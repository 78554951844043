<template>
  <section class="px-4 lg:px-0">
    <span class="flex items-center justify-center">
      <input
        v-model="uniqueCode"
        v-mask="'XXXXXXXXXXX'"
        class="unique-code-input px-3 mb-8"
        :placeholder="$t('register.form.unique_code')"
        @blur="onUniqueCodeBlur"
        @focus="uniqueCodeFocus = true"
        @input="$emit('unique-code-update', uniqueCode)"
      />
    </span>

    <p
      v-if="uniqueCodeRequired && !uniqueCodeFocus"
      class="unique-code-error-message text-sm text-center -mt-8 mb-3"
      v-html="$t('errors.unique_code_required')"
    ></p>

    <p
      v-else-if="uniqueCodeBurned && !uniqueCodeFocus"
      class="unique-code-error-message text-sm text-center -mt-8 mb-3"
      v-html="$t('errors.unique_code_burned')"
    ></p>

    <p
      v-else-if="uniqueCodeInvalid && !uniqueCodeFocus"
      class="unique-code-error-message text-sm text-center -mt-8 mb-3"
      v-html="$t('errors.unique_code_invalid')"
    ></p>

    <h3
      class="mb-1 font-bold text-base text-center uppercase"
      v-html="$t('register.code_exaplanation_title')"
    ></h3>

    <p class="max-w-xs mb-4 mx-auto text-base text-center" v-html="$t('register.code_explanation_description')"></p>

    <span class="flex items-center justify-center mb-16">
      <img
        class="block mx-auto object-contain w-auto h-48"
        src="@/assets/images/register/box.png"
        alt="Fragrance package code"
      />
    </span>
  </section>
</template>

<script>
export default {
  name: 'UniqueCodeForm',

  props: ['uniqueCodeInvalid', 'uniqueCodeBurned', 'uniqueCodeRequired'],

  data() {
    return {
      uniqueCode: '',
      uniqueCodeFocus: false,
    };
  },

  methods: {
    onUniqueCodeBlur() {
      this.uniqueCodeFocus = false;
      this.$emit('unique-code-blur', this.uniqueCode);
    },
  },
};
</script>

<style lang="scss">
.unique-code-input {
  width: 286px;
  height: 48px;
  border: 1px solid #000;
  outline: none;

  &::placeholder {
    color: #000;
  }
}
</style>
