<template>
  <validation-observer
    ref="signUpObserver"
    tag="form"
    v-slot="{ handleSubmit }"
    @submit.prevent
  >
    <n-radios
      id="iCivilId"
      v-model="signupForm.model.iCivilId"
      data-cy="input-civility"
      rules="requiredGender"
      design-type="box"
      selected-color="#E51F4D"
      :name="$t('register.form.names.gender').toLowerCase()"
      :values="$t('register.form.civilities')"
    >
      {{ $t('register.form.gender') }}
    </n-radios>

    <n-text
      data-cy="input-firstname"
      id="input-firstname"
      v-model="signupForm.model.sFirstName"
      type="text"
      rules="requiredFirstname|nameLatinFirstname"
      animation-type="above"
      error-trigger="blur"
      :name="$t('register.form.names.firstname').toLowerCase()"
      :placeholder="$t('register.form.first_name')"
    />

    <n-text
      data-cy="input-lastname"
      id="input-lastname"
      v-model="signupForm.model.sLastName"
      type="text"
      rules="requiredLastname|nameLatinLastname"
      animation-type="above"
      error-trigger="blur"
      :name="$t('register.form.names.lastname').toLowerCase()"
      :placeholder="$t('register.form.last_name')"
    />
    
    <n-mask
      data-cy="input-birthday"
      id="input-birthday"
      v-model="signupForm.model.oResponses.dBirthDate_1"
      type="tel"
      mask="##/##/####"
      :rules="`requiredBirthday|birthday|${birthdateAgeMinRule}|ageMax:100`"
      animation-type="label"
      error-trigger="blur"
      :name="$t('register.form.names.birthday').toLowerCase()"
      :placeholder="$t('register.form.birthday')"
    />

    <n-text
      data-cy="input-email"
      ref="input-email"
      id="input-email"
      v-model="signupForm.model.sEmail"
      type="email"
      animation-type="above"
      error-trigger="blur"
      :rules="`requiredEmail|email|alreadyRegistered:${userAlreadyExist}`"
      :name="$t('register.form.names.email').toLowerCase()"
      :placeholder="$t('register.form.email')"
    />

    <n-text
      data-cy="input-address"
      id="input-address"
      v-if="hasCode"
      v-model="signupForm.model.sAddr1"
      type="text"
      rules="requiredAddress|addressIsValid"
      animation-type="above"
      error-trigger="blur"
      :name="$t('register.form.names.address').toLowerCase()"
      :placeholder="$t('register.form.address')"
    />

    <n-mask
      data-cy="input-zipcode"
      id="input-zipcode"
      v-if="hasCode"
      v-model="signupForm.model.sZipcode"
      type="text"
      :mask="zipcodeMask"
      :rules="zipcodeRules"
      animation-type="label"
      error-trigger="blur"
      :name="$t('register.form.names.zipcode').toLowerCase()"
      :placeholder="$t('register.form.zipcode')"
    />

    <n-text
      data-cy="input-city"
      id="input-city"
      v-if="hasCode"
      v-model="signupForm.model.sTown"
      type="text"
      rules="requiredCity|nameLatinCity"
      animation-type="above"
      error-trigger="blur"
      :name="$t('register.form.names.city').toLowerCase()"
      :placeholder="$t('register.form.city')"
    />

    <template v-if="appConfig.activeDecli.name === 'fr'">
      <p class="age-mentions-fr">{{ $t('register_optin.code.mention_age') }}</p>

      <n-checkbox
        v-if="hasCode"
        data-cy="input-terms"
        id="input-terms"
        v-model="signupForm.terms"
        name="terms"
        rules="requiredCheckbox"
      >
        <dynamic :template="$t('register_optin.code.terms')" :data="{ updateModalConfig }"></dynamic>
      </n-checkbox>

      <n-checkbox
        v-if="hasCode"
        data-cy="input-optin"
        id="input-optin"
        v-model="signupForm.model.oOptins.iOptin_2"
        name="optin"
      >
        <dynamic :template="$t('register_optin.code.iOptin_2_label')" :data="{ updateModalConfig }"></dynamic>
      </n-checkbox>

      <p class="terms2" v-html="$t('register_optin.code.terms2')"></p>
    </template>

    <template v-else>
      <n-checkbox
        v-if="hasCode"
        data-cy="input-terms"
        id="input-terms"
        v-model="signupForm.terms"
        name="terms"
        rules="requiredCheckbox"
      >
        <dynamic :template="$t('register_optin.code.terms')" :data="{ updateModalConfig }"></dynamic>
      </n-checkbox>

      <n-checkbox
        v-if="hasCode"
        data-cy="input-optin-code"
        id="input-optin-code"
        v-model="signupForm.model.oOptins.iOptin_5"
        name="optincode"
      >
        <dynamic :template="$t('register_optin.code.iOptin_5_label')" :data="{ updateModalConfig }"></dynamic>
      </n-checkbox>

      <n-checkbox
        data-cy="input-optin"
        id="input-optin"
        v-model="signupForm.model.oOptins.iOptin_2"
        name="optin"
      >
        <p v-html="!hasCode ? $t('register_optin.no_code.iOptin_2_label') : $t('register_optin.code.iOptin_2_label')"></p>
      </n-checkbox>
    </template>

    

    <n-checkbox
      v-if="appConfig.activeDecli.name === 'it'"
      data-cy="input-optin-profillage"
      id="input-optin-profillage"
      v-model="signupForm.model.oOptins.iOptin_6"
      name="optinprofillage"
    >
      <dynamic :template="$t('register_optin.code.iOptin_6_label')" :data="{ updateModalConfig }"></dynamic>
    </n-checkbox>

    <span 
      v-if="userAlreadyExist" 
      class="block mb-4 text-xs text-error"
      v-html="$t('errors.email.alreadyUsed')"
    >
    </span>

    <p
      v-if="hasCode && uniqueCodeRequired"
      class="unique-code-error-message text-sm text-center mt-2 mb-2"
      v-html="$t('errors.unique_code_required')"
    ></p>

    <p
      v-if="hasCode && uniqueCodeBurned"
      class="unique-code-error-message text-sm text-center mt-2 mb-2"
      v-html="$t('errors.unique_code_burned')"
    ></p>

    <span class="table m-auto">
      <n-cta
        data-cy="submit-register"
        class="w-56 mb-4 mt-8"
        design-type="none"
        :disabled="isLoading"
        @click.native="$emit('force-submit'); handleSubmit(submit)"
      >
        {{ $t('register.form.submit') }}
      </n-cta>
    </span>

    <div class="w-full text-center text-black" v-html="$t('register.not_mandatory')"></div>
  </validation-observer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { NRadios, NText, NMask, NCheckbox } from '@team-uep/n-inputs';

import registerForm from '@/mixins/registerForm';
  
export default {
  name: 'RegisterSimpleForm',

  mixins: [registerForm],
  
  components: { NRadios, NText, NMask, NCheckbox },

  props: {
    isLoading: Boolean,
    userAlreadyExist: Boolean,
    hasCode: Boolean,
    codeValid: Boolean,
    uniqueCodeInvalid: Boolean,
    uniqueCodeRequired: Boolean,
    uniqueCodeBurned: Boolean,
  },

  data() {
    return {
      signupForm: {
        optin: 0,
        terms: false,
        model: {
          oResponses: {
            dBirthDate_1: '',
          },

          iCivilId: '',
          sEmail: '',
          sMobile: '',
          sZipcode: '',
          sLastName: '',
          sFirstName: '',
          sAddr1: '',
          sTown: '',
          oOptins: {
            iOptin_2: false,
            iOptin_5: false,
            iOptin_6: false,
          },
        },
      },
    };
  },

  computed: {
    ...mapGetters({ appConfig: 'config/appConfig', stateParams: 'user/stateParams' }),

    birthdateAgeMinRule() {
      const decliName = this.appConfig.activeDecli.name;
      const sixteenDeclis = ['be', 'it', 'nl'];
      const thirteenDeclis = ['uk'];
      const heighteenDeclis = ['at', 'de', 'es', 'ch', 'fr'];

      if (sixteenDeclis.indexOf(decliName) > -1) {
        return 'ageMin:16';
      } else if (thirteenDeclis.indexOf(decliName) > -1) {
        return 'ageMin:13';
      } else if (heighteenDeclis.indexOf(decliName) > -1) {
        return 'ageMin:18';
      }

      return 'ageMin:18';
    },

    zipcodeRules() {
      const decliName = this.appConfig.activeDecli.name;
      const fourDigitsDeclis = ['at', 'be', 'ch'];
      const fiveDigitsDeclis = ['fr', 'de', 'it', 'es'];

      if (fourDigitsDeclis.indexOf(decliName) > -1) {
        return 'requiredZipcode|min:4';
      } else if (fiveDigitsDeclis.indexOf(decliName) > -1) {
        return 'requiredZipcode|min:5';
      } else if (decliName === 'us') {
        return 'requiredZipcode|min:9';
      } else if (decliName === 'nl') {
        return 'requiredZipcode|min:6';
      } else if (decliName === 'uk') {
        return 'requiredZipcode|min:4';
      }

      return 'requiredZipcode';
    },

    zipcodeMask() {
      const decliName = this.appConfig.activeDecli.name;
      const fourDigitsDeclis = ['at', 'be', 'ch'];
      const fiveDigitsDeclis = ['fr', 'de', 'it', 'es'];
      if (fourDigitsDeclis.indexOf(decliName) > -1) {
        return '####';
      } else if (fiveDigitsDeclis.indexOf(decliName) > -1) {
        return '#####';
      } else if (decliName === 'us') {
        return '#####-####';
      } else if (decliName === 'nl') {
        return '#### AA';
      } else if (decliName === 'uk') {
        return 'XXXXX XXX';
      }

      return 'XXXXX';
    },
  },

  methods: {
    ...mapActions(['updateModalConfig']),
    submit () {
      this.$emit('submit', this.signupForm);
    },
  },

  mounted () {
    // URL params prefill on register form
    const urlParams = this.stateParams || this.$route.query;
    const inputEmailRef = this.$refs['input-email'];

    urlParams.e != null ? this.signupForm.model.sEmail = urlParams.e : null;
    urlParams.p != null ? this.signupForm.model.sFirstName = urlParams.p : null;
    urlParams.n != null ? this.signupForm.model.sLastName = urlParams.n : null;
    urlParams.c != null && (urlParams.c === '1' || urlParams.c === '2')
      ? this.signupForm.model.iCivilId = parseInt(urlParams.c)
      : null;
  
    // Disable email already used error message on email-input focus
    if (inputEmailRef && inputEmailRef.$el) {
      const inputEmailRefNode = (inputEmailRef.$el.getElementsByTagName('input') || [])[0];

      if (inputEmailRefNode) {
        inputEmailRefNode.onfocus = () => {
          this.$emit('update:user-already-exist', false);
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.n-input__radio-label ::v-deep{
  padding-right: 25px;
}

.n-checkbox ::v-deep {
  .n-input__checkbox {
    height: 26px;
  }
  .n-input__error-text {
    padding-left: 25px
  }
}

form {
  margin: 0 2rem;
}
.text-error {
  color: rgb(255, 59, 48);
}

.age-mentions-fr {
  margin-bottom: 20px;
}

.terms2 {
  padding-left: 26px;
}
</style>
