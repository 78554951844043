import i18n from './i18n';

/**
 * Get a list of countries with i18n countries names.
 *
 * @param {String} localeName
 * @returns A list of countries.
 */
export const getCountries = (localeName) => ([
  {
    name: i18n.t('countries.australia', localeName),
    id: 'au',
    value: 1,
    decli: 'en',
    allowed: false,
  },
  {
    name: i18n.t('countries.austria', localeName),
    id: 'at',
    value: 2,
    decli: 'at',
    decliDomain: 'at',
    allowed: false,
  },
  {
    name: i18n.t('countries.belgium', localeName),
    id: 'be',
    value: 3,
    decli: 'be',
    decliDomain: 'be',
    allowed: false,
  },
  {
    name: i18n.t('countries.bulgaria', localeName),
    id: 'bg',
    value: 4,
    decli: 'en',
    allowed: false,
  },
  {
    name: i18n.t('countries.canada', localeName),
    id: 'ca',
    value: 5,
    decli: 'en',
    allowed: false,
  },
  {
    name: i18n.t('countries.chile', localeName),
    id: 'cl',
    value: 6,
    decli: 'es',
    allowed: false,
  },
  {
    name: i18n.t('countries.croatia', localeName),
    id: 'hr',
    value: 7,
    decli: 'en',
    allowed: false,
  },
  {
    name: i18n.t('countries.czech_republic', localeName),
    id: 'cz',
    value: 8,
    decli: 'en',
    allowed: false,
  },
  {
    name: i18n.t('countries.france', localeName),
    id: 'fr',
    value: 9,
    decli: 'fr',
    decliDomain: 'fr',
    allowed: true,
  },
  {
    name: i18n.t('countries.germany', localeName),
    id: 'de',
    value: 10,
    decli: 'de',
    decliDomain: 'de',
    allowed: false,
  },
  {
    name: i18n.t('countries.hungary', localeName),
    id: 'hu',
    value: 11,
    decli: 'en',
    allowed: false,
  },
  {
    name: i18n.t('countries.iraq', localeName),
    id: 'iq',
    value: 12,
    decli: 'en',
    allowed: false,
  },
  {
    name: i18n.t('countries.ireland', localeName),
    id: 'ie',
    value: 13,
    decli: 'en',
    allowed: false,
  },
  {
    name: i18n.t('countries.israel', localeName),
    id: 'il',
    value: 14,
    decli: 'en',
    allowed: false,
  },
  {
    name: i18n.t('countries.italy', localeName),
    id: 'it',
    value: 15,
    decli: 'it',
    decliDomain: 'it',
    allowed: false,
  },
  {
    name: i18n.t('countries.jordan', localeName),
    id: 'jo',
    value: 16,
    decli: 'en',
    allowed: false,
  },
  {
    name: i18n.t('countries.lebanon', localeName),
    id: 'lb',
    value: 17,
    decli: 'en',
    allowed: false,
  },
  {
    name: i18n.t('countries.mexico', localeName),
    id: 'mx',
    value: 18,
    decli: 'es',
    allowed: false,
  },
  {
    name: i18n.t('countries.morocco', localeName),
    id: 'ma',
    value: 19,
    decli: 'en',
    allowed: false,
  },
  {
    name: i18n.t('countries.netherlands', localeName),
    id: 'nl',
    value: 20,
    decli: 'nl',
    decliDomain: 'nl',
    allowed: false,
  },
  {
    name: i18n.t('countries.new_zealand', localeName),
    id: 'nz',
    value: 21,
    decli: 'en',
    allowed: false,
  },
  {
    name: i18n.t('countries.poland', localeName),
    id: 'pl',
    value: 22,
    decli: 'en',
    allowed: false,
  },
  {
    name: i18n.t('countries.portugal', localeName),
    id: 'pt',
    value: 23,
    decli: 'en',
    allowed: false,
  },
  {
    name: i18n.t('countries.romania', localeName),
    id: 'ro',
    value: 24,
    decli: 'en',
    allowed: false,
  },
  {
    name: i18n.t('countries.russia', localeName),
    id: 'ru',
    value: 25,
    decli: 'en',
    allowed: false,
  },
  {
    name: i18n.t('countries.saudi_arabia', localeName),
    id: 'sa',
    value: 26,
    decli: 'en',
    allowed: false,
  },
  {
    name: i18n.t('countries.serbie', localeName),
    id: 'rs',
    value: 27,
    decli: 'en',
    allowed: false,
  },
  {
    name: i18n.t('countries.slovakia', localeName),
    id: 'sk',
    value: 28,
    decli: 'en',
    allowed: false,
  },
  {
    name: i18n.t('countries.south_africa', localeName),
    id: 'za',
    value: 29,
    decli: 'en',
    allowed: false,
  },
  {
    name: i18n.t('countries.spain', localeName),
    id: 'es',
    value: 30,
    decli: 'es',
    decliDomain: 'es',
    allowed: false,
  },
  {
    name: i18n.t('countries.switzerland', localeName),
    id: 'ch',
    value: 31,
    decliDomain: 'ch',
    // Has multiple language, check i18n.js
    declis: [
      {
        name: i18n.t('languages.french'),
        value: 'fr',
      },
      {
        name: i18n.t('languages.german'),
        value: 'de',
      },
    ],
    allowed: false,
  },
  {
    name: i18n.t('countries.turkey', localeName),
    id: 'tr',
    value: 32,
    decli: 'en',
    allowed: false,
  },
  {
    name: i18n.t('countries.uk', localeName),
    id: 'uk',
    value: 33,
    decli: 'uk',
    decliDomain: 'co.uk',
    allowed: true,
  },
  {
    name: i18n.t('countries.ukraine', localeName),
    id: 'ua',
    value: 34,
    decli: 'en',
    allowed: false,
  },
  {
    name: i18n.t('countries.united_arab_emirates', localeName),
    id: 'ae',
    value: 35,
    decli: 'en',
    allowed: false,
  },
  {
    name: i18n.t('countries.usa', localeName),
    id: 'us',
    value: 36,
    decli: 'us',
    decliDomain: 'com',
    allowed: false,
  },
]);
