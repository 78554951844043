<template>
  <div>
    <h3
      class="px-4 mb-4 font-bold text-21px text-center"
      v-html="hasCode ? $t('register.title_with_code') : $t('register.title')"
    ></h3>

    <p
      class="px-4 mb-16 text-base text-center"
      v-html="hasCode ? $t('register.subtitle_with_code') : $t('register.subtitle')"
    ></p>

    <unique-code-form
      v-if="hasCode"
      :unique-code-invalid="uniqueCodeInvalid"
      :unique-code-burned="uniqueCodeBurned"
      :unique-code-required="uniqueCodeRequired"
      @unique-code-blur="onUniqueCodeBlur"
      @unique-code-update="onUniqueCodeUpdate"
    />

    <register-simple-form
      :is-loading="isLoading"
      :user-already-exist="userAlreadyExist"
      :has-code="hasCode"
      :code-valid="uniqueCodeValid && !uniqueCodeInvalid && !uniqueCodeBurned"
      :unique-code-invalid="uniqueCodeInvalid"
      :unique-code-burned="uniqueCodeBurned"
      :unique-code-required="uniqueCodeRequired"
      @submit="postRegister"
      @force-submit="handleForceSubmit"
      @update:user-already-exist="userAlreadyExist = $event"
    />

    <div id="captcha">
      <vue-recaptcha
        id="captcha_id"
        ref="recaptcha"
        size="invisible"
        sitekey="6Lf0uhwUAAAAAAZQK0AfIcp6wMgQNO_EOoFg9zND"
        :load-recaptcha-script="true"
        @verify="onVerify"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VueRecaptcha from 'vue-recaptcha';

import { removeSpaces } from '@/helpers';
import { getCountries } from '@/countries';
import RegisterSimpleForm from '@/components/RegisterSimpleForm';
import UniqueCodeForm from '@/components/ui/UniqueCodeForm';

export default {
  name: 'Register',

  components: {
    VueRecaptcha, 
    RegisterSimpleForm,
    UniqueCodeForm,
  },

  data() {
    return {
      hasCode: true,
      // Not displayed to user, must be sent to back-end
      uniqueCode: '',
      uniqueCodeInvalid: false,
      uniqueCodeBurned: false,
      uniqueCodeValid: false,
      uniqueCodeRequired: false,

      displayRelog: false,
      relogEmailSent: false,
      userAlreadyExist: false,
      isLoading: false,

      countries: getCountries(),
    };
  },

  computed: {
    ...mapGetters({ appConfig: 'config/appConfig', stateParams: 'user/stateParams' }),

    currentCountry() {
      return this.countries.find((country) => this.appConfig.activeDecli.name === country.id);
    },
  },

  methods: {
    handleForceSubmit() {
      if (!this.uniqueCode) {
        this.uniqueCodeRequired = true;
      }
    },

    onUniqueCodeUpdate(value) {
      if (value && value.length > 0) {
        this.uniqueCodeRequired = false;
      } else {
        this.uniqueCodeRequired = true;
        this.uniqueCodeInvalid = false;
        this.uniqueCodeBurned = false;
      }
    },

    onUniqueCodeBlur(code) {
      if (!code || code === '') {
        return;
      }

      this.isLoading = true;

      this.$requestAPI.uniqueCode(code)
        .then((response) => {
          console.log(response);

          this.uniqueCode = response.data.data[0];
          this.uniqueCodeInvalid = false;
          this.uniqueCodeBurned = false;
          this.uniqueCodeValid = true;
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.name === 'ApiError') {
            if (error.response && error.response.status === 404) {
              this.uniqueCodeInvalid = true;
              this.uniqueCodeBurned = false;
              this.uniqueCodeValid = false;
              this.uniqueCodeRequired = false;
            } else if (error.response && error.response.status === 409) {
              this.uniqueCodeInvalid = false;
              this.uniqueCodeBurned = true;
              this.uniqueCodeValid = false;
              this.uniqueCodeRequired = false;
            }
          }

          this.isLoading = false;
        });
    },

    submitRelog(relogForm) {
      this.$requestAPI.sendRelogEmail({ sEmail: relogForm.model.sEmail })
        .catch(console.log)
        .finally(() => {
          this.relogEmailSent = true;
          this.isLoading = false;
        });
    },

    postRegister(signupForm) {
      if (this.hasCode && !this.uniqueCodeValid) {
        return;
      }

      this.isLoading = true;

      const urlParams = new URLSearchParams(window.location.search);
      const lang = urlParams.get('lang') ? urlParams.get('lang') : null;

      // In case we need to update the data before sending them
      let userData = JSON.parse(JSON.stringify(signupForm.model));

      // Clean data before calling the API
      userData.sFirstName = removeSpaces(userData.sFirstName);
      userData.sLastName = removeSpaces(userData.sLastName);
      delete userData.sMobile;

      // Add current country ID
      userData.oResponses.iQuestion_100 = this.currentCountry ? parseInt(this.currentCountry.value, 10) : 36;

      // Set iQuestion_101 for current language for .ch domain
      if (lang && this.appConfig.activeDecli.name === 'ch') {
        const iQuestion_101 = lang === 'fr' ? 1 : 2;
        userData.oResponses.iQuestion_101 = iQuestion_101;
      }

      // Handle optin format
      // - iOptin_2 is default optin
      // - iOptin_5 is optin with `hasCode`
      if (userData.oOptins) {
        if (userData.oOptins.iOptin_2) {
          userData.oOptins.iOptin_2 = 1;
        }

        if (userData.oOptins.iOptin_5) {
          userData.oOptins.iOptin_5 = 1;
        }

        if (userData.oOptins.iOptin_6) {
          userData.oOptins.iOptin_6 = 1;
        }

        if (!userData.oOptins.iOptin_2) {
          delete userData.oOptins.iOptin_2;
        }

        if (!userData.oOptins.iOptin_5) {
          delete userData.oOptins.iOptin_5;
        }

        if (!userData.oOptins.iOptin_6) {
          delete userData.oOptins.iOptin_6;
        }
      }

      // Make sure to delete oOptin object if we don't have any content inside
      if (Object.keys(userData.oOptins).length <= 0) {
        delete userData.oOptins;
      }

      // Delete unused fields that are required only if you have a unique code
      if (!this.hasCode) {
        delete userData.sZipcode;
        delete userData.sAddr1;
        delete userData.sTown;
      }
      // Add required fields when you have a unique code
      else {
        userData.oResponses.sQuestion_200 = this.uniqueCode;
        userData.sZipcode = userData.sZipcode.replace(/\s/g, '');
      }

      this.$requestAPI.register(userData)
        .then(() => {
          this.$mmTro.conversion(this.$route.query)
          this.$router.push({ name: 'confirmation' }).catch(console.log);
        })
        .catch((error) => {
          // Test if error is an ApiError object
          if (error.name === 'ApiError') {
            if (error.response && error.response.status === 503) {
              const onRecaptcha = () => {
                this.$off("recaptcha-valid", onRecaptcha);
                this.postRegister(signupForm);
              };

              this.$on("recaptcha-valid", onRecaptcha);

              this.$refs.recaptcha.reset();
              this.$refs.recaptcha.execute();
            }

            if (error.response && error.response.status === 409) {
              this.userAlreadyExist = true;
            }
          }

          this.isLoading = false;
        });
    },

    onVerify(response) {
      console.log('onVerify', response);
      return this.sendCaptchaResult(response);
    },

    sendCaptchaResult(sCaptchaResponse) {
      const sBlockedUrl = [
        `${document.location.protocol}//${document.location.host}/api/v0/`,
        `${this.appConfig.baseName}/${this.appConfig.activeDecli.apiVersion}`,
        `/inscription/register`,
      ].join('');

      return this.$requestAPI.sendCaptchaToken({ sBlockedUrl, sCaptchaResponse })
        .then(() => {
          this.$emit('recaptcha-valid');
        })
        .catch(console.log);
    },
  },

  mounted () {
    this.$mmTro.sendRtg();
  },
};
</script>

<style lang="scss" scoped>
#captcha {
  visibility: hidden;
}
</style> 
